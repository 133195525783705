import * as CryptoJS from 'crypto-js';
let envVar = window["__env"] || {};
import { LogLevel, Configuration, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';

function decrypt(data: string) {
  const secretKey = '!n@dev2023';
  try {
    if (CryptoJS.AES.decrypt(data, secretKey)) {
      const decrypted = CryptoJS.AES.decrypt(data, secretKey).toString(CryptoJS.enc.Utf8);
      return decrypted;
    } else {
      return data;
    }
  } catch (e) {
    // console.log(e);
  }
}

export const environment = {
  production: true,
  SCHOOL_ADMIN_RESENT_TIMER: 259200,         //72 hours
  DEPUTY_ADMIN_RESENT_TIMER: 259200,         //72 hours
  APP_VERSION: "2.55.4",
  APP_URL: decrypt(envVar.APP_URL),
  API_URL: decrypt(envVar.API_URL),
  INACTIVITY_LOGOUT_TIME: decrypt(envVar.INACTIVITY_LOGOUT_TIME),
  MAX_LOGIN_ATTEMPTS: decrypt(envVar.MAX_LOGIN_ATTEMPTS),
  WEB_SOCKET_BASE_URL: decrypt(envVar.WEB_SOCKET_BASE_URL),
  UPLOAD_API_URL: decrypt(envVar.UPLOAD_API_URL),
  ACTIVE_ENVIRONMENT: decrypt(envVar.ACTIVE_ENVIRONMENT),
  API_SUBSCRIPTION_KEY: decrypt(envVar.API_SUBSCRIPTION_KEY),
  CONTAINER_URL: decrypt(envVar.CONTAINER_URL),
  

  FIREBASE_CONFIGURATION: {
    apiKey: decrypt(envVar.FIR_CONF_API_KEY),
    authDomain: decrypt(envVar.FIR_CONF_AUTH_DOMAIN),
    projectId: decrypt(envVar.FIR_CONF_PROJECT_ID),
    storageBucket: decrypt(envVar.FIR_CONF_STORAGE_BUCKET),
    messagingSenderId: decrypt(envVar.FIR_CONF_MESSAGING_SENDER_ID),
    appId: decrypt(envVar.FIR_CONF_APP_ID),
    measurementId: decrypt(envVar.FIR_CONF_MEASUREMENT_ID),
    vapidKey: decrypt(envVar.FIR_CONF_VAPID_KEY)
  },

msalConfig: {
  auth: {
    clientId: decrypt(envVar.AZURE_CLIENTID),
    authority: 'https://login.microsoftonline.com/'+decrypt(envVar.AZURE_TENANTID),
    redirectUri:  window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configuring local storage to store the tokens
    storeAuthStateInCookie:true,
    secureCookies: true, 
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {  
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
        }
      }
    }
  }
},
msalAngularConfig: {
  interactionType: InteractionType.Redirect, // Set to 'redirect' or 'popup'
  authRequest: {
      scopes: ['user.read', 'openid', 'profile'], // Define the scopes you want to request
  },
},
msalInterceptorConfig: {
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']]
  ])
}
};