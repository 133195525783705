<div class="container-fluid">
    <div class="row">
        <div class="col-1 offset-10">
            <mat-icon class="cancel-btn" (click)="cancel()">close</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
            <span class="font-weight-bold heading-text">{{pgtitle}}</span>
        </div>
    </div>
    <div class="gutter-space"></div>
    <div class="row">
        <form [formGroup]="schoolMapForm">
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="filter-by-status">
                        <mat-label>School List</mat-label>
                        <mat-select formControlName="selectedSchool" #selectedSchool required>
                            <mat-option *ngFor="let school of schoolList" [value]="school?.id">
                                {{school?.schoolName}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="schoolMapForm.get('selectedSchool')?.invalid && schoolMapForm.get('selectedSchool')?.touched">
                            Please select a school.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="gutter-space"></div>
    <div class="gutter-space"></div>
    <div class="row button-div justify-content-center">
        <button class="app-btn filled green-bg large confirm-btn"
            [disabled]="schoolMapForm.get('selectedSchool')?.value === ''" (click)="saveSchool()">
            <span>Save</span>
        </button>
    </div>
</div>