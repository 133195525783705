<div class="container-fluid">
    <div class="row">
        <div class="col-1 offset-10">
            <mat-icon class="cancel-btn" (click)="cancel()">close</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
            <span class="font-weight-bold heading-text">{{pgtitle}}</span>
        </div>
    </div>
    <div class="gutter-space"></div>
    <div class="row">
        <form [formGroup]="schoolChangeForm">
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="filter-by-status">
                        <mat-label>Select School</mat-label>
                        <mat-select formControlName="selectedSchool" #selectedSchool required>
                            <mat-option *ngFor="let school of schoolList" [value]="school?.schoolid"
                                [disabled]="currentSchoolId === school?.schoolid || school?.isSchoolDisable === 1">
                                <div class="school-option">
                                    <div class="icon-container">
                                        <mat-icon *ngIf="currentSchoolId === school?.schoolid">check_circle</mat-icon>
                                    </div>
                                    <span>{{school?.schoolName}}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="schoolChangeForm.get('selectedSchool')?.invalid && schoolChangeForm.get('selectedSchool')?.touched">
                            Please select a school.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="gutter-space"></div>
    <div class="gutter-space"></div>
    <div class="gutter-space"></div>
    <div class="row button-div justify-content-center">
        <button class="app-btn filled green-bg large confirm-btn"
            [disabled]="schoolChangeForm.get('selectedSchool').value === currentSchoolId" (click)="confirm()">
            <span>Confirm</span>
        </button>
    </div>
</div>