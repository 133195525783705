<div class="container-fluid">
    <div class="row">
        <div class="col-1 offset-11">
            <mat-icon class="cancel-btn" (click)="cancel()">close</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
            <span class="font-weight-bold heading-text">{{pgtitle}}</span>
        </div>
        <div class="gutter-space"></div>
        <form [formGroup]="downloadReportDialogForm">
            <ng-container *ngIf="isSchoolReport">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="filter-by-status">
                            <mat-label>Select State</mat-label>
                            <mat-select formControlName="state">
                                <mat-option value='all'>All</mat-option>
                                <mat-option *ngFor="let state of stateList" [value]="state.abbreviation">{{state.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="filter-by-status">
                            <mat-label>Select Date Range</mat-label>
                            <mat-select formControlName="dateRangeType">
                                <mat-option value="custom">Custom</mat-option>
                                <mat-option value="fromTheBeginning">From the Beginning</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="getFieldR('dateRangeType').value === 'custom'">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>From</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" [max]="maxDate" placeholder="MM/DD/YYYY"
                                #startDate formControlName="startDate" name="startDate" (dateChange)="onDateChange()">
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error
                                *ngIf="getFieldR('startDate')?.invalid && !getFieldR('startDate')?.hasError('matDatepickerMax') && !getFieldR('startDate')?.hasError('greaterDateError') && getFieldR('startDate').touched">Please select the 'From Date'.
                            </mat-error>
                            <mat-error *ngIf="getFieldR('startDate')?.hasError('matDatepickerMax')">Future date is not allowed.
                            </mat-error>
                            <mat-error *ngIf="getFieldR('startDate')?.hasError('greaterDateError')">'From date' should not be greater than the 'To Date'.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>To</mat-label>
                            <input matInput [matDatepicker]="endDatePicker" [max]="maxDate" placeholder="MM/DD/YYYY"
                                #endDate formControlName="endDate" name="endDate" (dateChange)="onDateChange()">
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                            <mat-error
                                *ngIf="getFieldR('endDate')?.invalid && !getFieldR('endDate')?.hasError('matDatepickerMax') && getFieldR('endDate').touched">Please select the 'To Date'.
                            </mat-error>
                            <mat-error *ngIf="getFieldR('endDate')?.hasError('matDatepickerMax')">Future date is not allowed.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>

            <ng-container>
                <div class="row">
                    <div class="col-lg-6">
                        <mat-form-field class="fileTypeField">
                            <!-- Role selection -->
                            <mat-label>Select file format</mat-label>
                            <mat-select formControlName="fileType" #fileType>
                                <mat-option value="xlxs">.xls</mat-option>
                            </mat-select>
                            <mat-error *ngIf="getFieldR('fileType')?.invalid && getFieldR('fileType').touched">Please select a file format.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
    <div class="row button-div justify-content-center">
        <button class="download-btn" (click)="getDonwloadLink(schoolid)">
            <span>Download</span>
            <mat-icon class="mat-download-icon">save_alt</mat-icon>
        </button>
    </div>
</div>