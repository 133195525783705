import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/auth.service';
import { PushNotificationService } from './core/services/push-notification.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscriber, Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'soucs';
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  routerSub: Subscription;

  constructor(
    private _authService: AuthService,
    private localStorageService: LocalStorageService,
    private _pushNotificationService: PushNotificationService,
    private idle: Idle,
    private keepalive: Keepalive,
    private cd: ChangeDetectorRef,
    private router: Router,
    private location: Location,
    private msalService: MsalService
  ) {
    //1 min
    this.idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
    //45 min     
    this.idle.setTimeout(Number(environment.INACTIVITY_LOGOUT_TIME));


    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
      this.localStorageService.set('lastPing', new Date());

    });
    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      // console.log(`${this.idleState} ${new Date()}`)
      this.countdown = null;
      this.cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed oPnaut
    this.idle.onTimeout.subscribe(() => {
      this._authService.logout();
      this.idleState = "TIMED_OUT"
    });

    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe(seconds => {
      this.countdown = seconds
    });

    // set keepalive parameters, omit if not using keepalive
    this.keepalive.interval(30); // will ping at this interval while not idle, in seconds
    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date()
      this.localStorageService.set('lastPing', new Date());
      // console.log("last ping "+this.lastPing);
    }); // do something when it pings   

    //   this.routerSub = this.router.events.subscribe((val) => {

    //     let currentPath = this.location.path();
    //     if(currentPath.search(/auth/gi) == -1)
    //         idle.watch();
    //     else
    //         idle.stop();

    // });
  }

  ngOnInit() {
    console.log('App Version: ', environment.APP_VERSION);
    const SoucsToken = this.msalService.instance.getAllAccounts().length > 0 ? true : false;
    if (SoucsToken) {
      this._authService.checkOctaAdminLogin();
    } else {
      const userData = this.localStorageService.get('UserData');
      if (userData) {
        this._authService.checkSoucsAdminLogin(JSON.parse(this.localStorageService.decryptData(userData)));
      }

    }
    this.reset();
    this._pushNotificationService.requestNotifyPermission();
    this._pushNotificationService.listenPushNotification();
  }
  reset() {

    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

}
