import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(
    private readonly _router: Router,
    private cookieService: CookieService,
    private msalService: MsalService
  ) {

  }
  /**
   * Will set the data into session storage
   * @storageKey: The key against which the data is to store
   * @data: The data to store
   */
  set(storageKey: string, data: any) {
    this.cookieService.set(storageKey, data, null, '/', null, true, 'Strict');
    //localStorage.setItem(storageKey, data);
  }

  /**
   * Will get the data from session storage
   * @storageKey: The key against which the data is stored
   */
  get(storageKey: string) {
    return this.cookieService.get(storageKey);
    //return localStorage[storageKey];
  }

  /**
   * Will remove the data from session storage
   * @storageKey: The key against which the data is stored
   */
  remove(storageKey: string) {

    return this.cookieService.delete(storageKey);
    //delete localStorage[storageKey];
  }

  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();
    } catch (e) {
    }
  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, 'secret key 123');
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
    }
  }
  removeSeessionData() {
    this.cookieService.delete('adminType');
    this._router.navigate(['/auth/home']);
  }

  removeAll() {
    return this.cookieService.deleteAll('/');
  }

  getAdminType() {
    let adminType = this.cookieService.get('UserData') ? 'SCHOOL_ADMIN' : 'SOUCS_ADMIN';
    return adminType;
  }

  isSchoolAdmin() {
    if (this.getAdminType() === 'SCHOOL_ADMIN') {
      return true;
    } else {
      return false;
    }
  }
  isDeputyAdmin() {
    if (this.getAdminType() === 'SCHOOL_ADMIN' && JSON.parse(this.decryptData(this.cookieService.get('UserData'))).isDeputyAdmin == 1) {
      return true;
    } else {
      return false;
    }
  }

  isSoucsAdmin() {
    if (this.getAdminType() === 'SOUCS_ADMIN') {
      return true;
    } else {
      return false;
    }
  }

  getSchoolAdminUserData() {
    let userData = this.cookieService.get('UserData') ? this.decryptData(this.cookieService.get('UserData')) : ''
    if (userData) {
      return JSON.parse(this.decryptData(this.cookieService.get('UserData')));
    } else {
      return;
    }
  }

  getSchoolAdminSchoolId() {
    let userData = this.cookieService.get('UserData') ? this.decryptData(this.cookieService.get('UserData')) : ''
    if (userData) {
      return JSON.parse(this.decryptData(this.cookieService.get('UserData'))).schoolid;
    } else {
      return 0;
    }
  }

  getSchoolAdminSchoolName() {
    let userData = this.cookieService.get('UserData') ? this.decryptData(this.cookieService.get('UserData')) : ''
    if (userData) {
      return JSON.parse(this.decryptData(this.cookieService.get('UserData'))).schoolName;
    } else {
      return '';
    }
  }
  getSoucsAdminToken() {
    const activeAccount = this.msalService.instance.getActiveAccount();
    const request: SilentRequest = {
      scopes: ["user.read"], // Replace with your required scopes
      account: activeAccount,
    };
    if (!activeAccount) {
      return Promise.reject("No active account.Please log in");
    }
    return this.msalService.acquireTokenSilent(request).toPromise()
      .then(response => response.accessToken)
      .catch(error => {
        throw error;
      });
  }
}
