import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-recommended-dismiss-dialog',
  templateUrl: './recommended-dismiss-dialog.component.html',
  styleUrl: './recommended-dismiss-dialog.component.scss'
})
export class RecommendedDismissDialogComponent implements OnInit {
  pgtitle = '';
  recommendedDismissForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data,
    public dialogRef: MatDialogRef<RecommendedDismissDialogComponent>
  ) {
    if (data) {
      this.pgtitle = data.pgtitle || this.pgtitle;
    }
  }

  ngOnInit(): void {
    // Mat dialog position change
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.position = { top: '80px' };
    this.dialogRef.updatePosition(matDialogConfig.position);

    this.recommendedDismissForm = new FormGroup({
      'dismissReason': new FormControl('', [Validators.required])
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  getFieldValue(name: string) {
    return this.recommendedDismissForm.get(name);
  }

  submit() {
    if (this.recommendedDismissForm.valid) {

      this.dialogRef.close({
        'dismissReason': this.getFieldValue('dismissReason').value
      });
    } else {
      this.recommendedDismissForm.markAllAsTouched();
    }
  }
}
