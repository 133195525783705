import { gql } from 'apollo-angular';

export const searchQuery = gql`
  query($page:Float!,$keyword:String!,
    $query:String!,$filter:String!, $limit:Float!, $orderBy:String!, $order:String!,$type:String!){
    findSchoolSearchByQuery(schoolSearchOptionInput:{
      page:$page,keyword:$keyword,query:$query,filter:$filter, limit:$limit, orderBy:$orderBy, order:$order,type:$type
    }){
      count,
      schools {
        id,
        schoolName,
        stateName,
        zipcode,
        cityName,
        creationTime,
        addressFirst,
        status,
        districtName,
        adminActiveStatus,
        nces,
        banner,
        status_text,
        deactivateReason,
        isValidUsZipCode,
        type,
        activeStudent,
        pendingStudent,
        school_admin_status,
        isAdminDisableResendEmail,
        adminEnableResendVerificationMailTime
      }
    }
 }`;

export const updateSchoolStatusQuery = gql`
mutation updateSchoolStatus(
  $id: Float!,
  $status: String!,
  $deactivateReason:String!
) {
  updateSchoolStatus(updateSchoolStatusInput: {
    id: $id,
    status: $status, 
    deactivateReason: $deactivateReason  
  }) {
    id,
    status,
    deactivateReason
  }
}`;
export const SchoolDetailById = gql`query($id:Float!){
  getSchoolDetailById(id:$id){
    id,
        schoolName,
        countryName,
        stateName,
        cityName,
        districtName,
        addressFirst,
        addressSecond,
        zipcode,
        schoolProfile,
        adminName,
        adminEmail,
        adminPhone,
        creationTime,
        updationTime,
        emailNotificationStatus,
        status,
        nces,
        banner,
        isSysGenNces,
        deactivateReason,
        deactivateTime,
        isUniversity,
        type,
        status_text,
        totalStudent,
        activeStudent,
        pendingStudent,
        inActiveStudent,
        preApproveStudent,
        adminActiveStatus,
        school_admin_status,
        isAdminDisableResendEmail,
        adminEnableResendVerificationMailTime,
        isDeputyAdminDisableResendEmail
        deputyAdminName,
        deputyAdminEmail,
        deputyAdminPhone,
        deputy_school_admin_status,
        deputyAdminEnableResendVerificationMailTime
  }
}`;


export const schoolQuery = gql`query($id:Float!){
  findById(id:$id){
    id,
    schoolName,
    countryName,
    stateName,
    zipcode,
    cityName,
    creationTime,
    addressFirst,
    addressSecond,
    schoolProfile,
    mainName,
    mainEmail,
    mainPhone,
    districtName,
    nces,
    isSysGenNces,
    banner,
    deactivateReason,
    adminActiveStatus,
    isUniversity,
    status
  }
}`;

export const distictQuery = gql`
query($keyword:String!,$state:String!){
  findDistrictsByStateAndKeyword(keyword:$keyword,state:$state){
    districtname,
    id
  }
}`;

export const schoolValidationQuery = gql`
mutation schoolValidation($input:SchoolValidationInput!){
  schoolValidation(schoolValidationInput:$input){
    validation_status
    validation_message
  }
}`;

// Soucs Admin: Get State and city using zip code
export const getStateAndCityUsingZipCode = gql`
mutation($zip:Float!){
  getStateAndCityByAdmin(stateCityInput: {
    zip: $zip
  }){
    state_name
    state_id
    city
  }
}`;

// Get system generated nces id.
export const getSysGeneratedNcesQuery = gql`
query($isUniversity:Float!){
  getSystemGeneratedNcesId(isUniversity:$isUniversity){
    nces
  }
}`;

export const resendInviteLinkQuery = gql`
mutation ($schoolId: Float!) {
  resendSchoolAdminInvitationEmail(schoolId: $schoolId) {
    id
    schoolid
  }
}`;

export const deputyAdminResendInviteLinkQuery = gql`
mutation ($schoolId: Float!,$isDeputyAdmin:Float!) {
  resendSchoolAdminInvitationEmail(schoolId: $schoolId,isDeputyAdmin: $isDeputyAdmin) {
    id
    schoolid
    isDeputyAdmin
  }
}`;

// Get applicable school list to changed among.
export const getAllAssociatedSchoolsQuery = gql`
query {
	getSameAdminSchool {
		id,
		schoolid,
		schoolName,
		schoolAdminName,
		schoolAdminEmail,
		schoolAdminPhone,
    isSchoolDisable
	}
}`;

// School Admin: switch to new selected school when multiple school assigend.
export const switchToNewSchoolQuery = gql`
query switchSchoolAdmin($schoolId:Float!) {
	switchSchoolAdmin(schoolId: $schoolId) {
		user {
			id,
			schoolid,
			name,
			email,
			phone,
			status
		}
		token,
    refresh_token,
	}
}`;

// Soucs Admin:- Mass resend email invitation to school admins with pending status.
export const massResendInvitationMail = gql`
mutation massResendSchoolAdminInvitationEmail(
  $isForAllSchool:Float!,
  $ids:[Float!]!
){
  massResendSchoolAdminInvitationEmail(massResendSchoolAdminInviteEmailInput:{
    isForAllSchool:$isForAllSchool,
    ids:$ids
  }){
		failEmailSendSchoolAdmin {
			school_id,
			school_name,
			name,
			email,
			error
		}
  }
}`;
export const deputyAdminMassResendInvitationMail = gql`
mutation massResendSchoolAdminInvitationEmail(
  $isForAllSchool:Float!,
  $ids:[Float!]!
  $isDeputyAdmin:Float!
){
  massResendSchoolAdminInvitationEmail(massResendSchoolAdminInviteEmailInput:{
    isForAllSchool:$isForAllSchool,
    ids:$ids
    isDeputyAdmin:$isDeputyAdmin

  }){
		failEmailSendSchoolAdmin {
			school_id,
			school_name,
			name,
			email,
			error
		}
  }
}`;

export const listDeputyAdminQuery = gql`
  query($keyword:String!,
    $query:String!,$filterStatus:String!,$page:Float!,$limit:Float!, $orderBy:String!, $order:String!){
      getDeputyAdminRequestList(deputyAdminRequestListInput:{
      keyword:$keyword,query:$query,filterStatus:$filterStatus,
      page:$page, limit:$limit, orderBy:$orderBy, order:$order
    }){
      count,
      deputyAdminRequestList {
        deputyAdminId,
        deputyAdminName,
        deputyAdminEmail,
        adminId,
        adminName,
        adminEmail,
        schoolId,
        schoolName,
        schoolStateName,
        creationTime,
        deputyAdminVerificationStatus,
        type,
        deputyAdminEnableResendVerificationMailTime,
        isDeputyAdminDisableResendEmail
      }
    }
 }`;
export const deleteSchoolBySoucsAdminQuery = gql`
mutation ($id: Float!) {
  deleteSchoolBySoucsAdmin(id: $id) {
    id
    schoolName
  }
}`;

export const schoolRecommendationList = gql`query(
$page:Float!,$limit:Float!,$keyword:String,$searchOption:String, 
$order:String,$orderBy:String,$mapped_status:String! ){
    schoolRecommendationList(schoolRecommendationListInput:{
    page:$page, limit:$limit,keyword:$keyword,searchOption:$searchOption,
    orderBy:$orderBy, order:$order,mapped_status:$mapped_status
  }){
    count,
    school_recommendation_list {
      id,
      school_name,
      zip_code,
      address,
      recommended_by,
      recommended_by_name,
      recommended_by_email,
      received_date,
      mapped_status
    }
  }
}`;

export const dismissRecommendedSchoolQuery = gql`
mutation($isAcceptedRecommendationSchool:Float!, $recommendationSchoolId:Float!, $dismissReason:String!){
  manageSchoolRecommendation(manageSchoolRecommendationInput:{
    isAcceptedRecommendationSchool: $isAcceptedRecommendationSchool
    recommendationSchoolId: $recommendationSchoolId
    dismissReason: $dismissReason
  }){
   id,
    school_name,
    map_school_id
  }
}`;

export const getAllMappedSchoolQuery = gql`
query($isActiveSchool:Float!) {
	schools(isActiveSchool: $isActiveSchool) {
	  id,
    schoolName,
    status
	}
}`;

export const mappedSchoolQuery = gql`
mutation manageSchoolRecommendation($isAcceptedRecommendationSchool:Float,$recommendationSchoolId:Float,$schoolId:Float){
  manageSchoolRecommendation(manageSchoolRecommendationInput:{schoolId:$schoolId,isAcceptedRecommendationSchool:$isAcceptedRecommendationSchool,recommendationSchoolId:$recommendationSchoolId}){
   id
   school_name
   
  }
}`;

export const schoolRecommendationListBySchoolQuery = gql`
query($id:Float!,$page:Float!,$limit:Float!,$orderBy:String!,$order:String!){
  schoolRecommendationListBySchool(schoolRecommendationListBySchoolInput:{
    id: $id,
    page:$page,
    limit:$limit,
    orderBy:$orderBy,
    order:$order
  }){
    schoolRecommendationList{
      id,
      school_name,
      map_school_id,
      updated_at
    },
    count
  }
}`
