<div class="container-fluid">
    <div class="row">
        <div class="col-1 offset-11">
            <mat-icon class="cancel-btn" (click)="cancel()">close</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
            <span class="font-weight-bold heading-text">{{pgtitle}}</span>
        </div>
    </div>
    <div class="row">
        <form [formGroup]="recommendedDismissForm">

            <div class="row mt-3">
                <div class="col-12">
                    <label class="color-gray">Provide explanation for dismissal:</label>
                    <mat-form-field class="top-margin-minus-10-px">
                        <textarea matInput maxlength="250" #dismissReason formControlName="dismissReason"
                            name="dismissReason"></textarea>
                        <mat-hint align="end" class="font-italic p-1">{{dismissReason.value.length}} /
                            250</mat-hint>
                        <mat-error
                            *ngIf="getFieldValue('dismissReason')?.invalid && getFieldValue('dismissReason').touched">
                            Please provide the explanation.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="row button-div justify-content-center">
        <button class="submit app-btn filled green-bg" (click)="submit()"
            [disabled]="getFieldValue('dismissReason')?.value?.trim() === ''">
            <span>Submit</span>
        </button>
    </div>
</div>