import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../model/user.model';
import { HttpService } from './http.service';
import { LocalStorageService } from './local-storage.service';
import { UtilityService } from './utility.service';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';


const logoutQuery = gql`
mutation schoolAdminLogoutAction($schoolAdminId:Float!,$soucsAdminEmail:String!){
  schoolAdminLogoutAction(schoolAdminId:$schoolAdminId,soucsAdminEmail:$soucsAdminEmail){
    id
  }
}`;
@Injectable({
  providedIn: 'root'
})


export class AuthService {
  user = new BehaviorSubject<any>(null);
  tokenExpirationTimer: any;
  constructor(private readonly _router: Router,
    private readonly _toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private readonly _utilityService: UtilityService,
    private readonly _localStorage: LocalStorageService,
    private readonly _apollo: Apollo,
    private readonly _errorHandler: CustomErrorHandlerService,
    private httpService: HttpService,
    private msalService: MsalService,
  ) {
    this._errorHandler._apollo = this._apollo;
  }


  // School Admin login
  signIn(user: Object) {
    const { name, schoolid, id, __typename: usertype } = user['user'];
    let expireDt = (JSON.parse(atob(user['token'].split('.')[1]))).exp * 1000;
    let emailid = user['user'].email;
    let schoolName = '';
    let isDeputyAdmin = user['user'].isDeputyAdmin;
    this._localStorage.set('lastPing', new Date());
    this.authenticatedUser(emailid, name, schoolid, schoolName, usertype, user['token'], expireDt, isDeputyAdmin, id, user['refresh_token']);
  }

  checkSoucsAdminLogin(userData) {
    const _token = this.httpService.getCookie('_token')
    if (!_token) {
      this.schoolAdminLogout();
      return;
    }
    let tokenExpirationDate = JSON.parse(this.httpService.getCookie('_tokenExpirationDate') || '');
    const expirationDate = new Date(new Date().getTime() + tokenExpirationDate * 1000);
    this.httpService.setCookie('_tokenExpirationDate', JSON.stringify(expirationDate));
    const loggedInUser = new User(userData.email, userData.name, userData.schoolid, userData.schoolName, userData.usertype, userData.isDeputyAdmin, userData.id);
    if (this.token) {
      this.user.next(loggedInUser);
      this._utilityService.routeReuseUrl();
      // this._router.navigate(['/dashboard']);
      const expirationTime = new Date(JSON.parse(this.httpService.getCookie('_tokenExpirationDate') || '')).getTime() - new Date().getTime();
      this.autoSignOut(expirationTime);
    }
  }

  token() {
    let _tokenExpirationDate = new Date(JSON.parse(this.httpService.getCookie('_tokenExpirationDate') || ''))
    if (!_tokenExpirationDate || new Date() > _tokenExpirationDate) {
      return null;
    }
    return this.httpService.getCookie('_token')

  }


  checkOctaAdminLogin() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._utilityService.routeReuseUrl();

  }
  isIdle() {
    let lastPing = new Date(this._localStorage.get('lastPing'))
    //more than 45 mins

    if (((new Date().getTime() - lastPing.getTime()) / 1000) >= Number(environment.INACTIVITY_LOGOUT_TIME)) {
      return true;
    }
    return false;
  }
  autoLoginIn() {
    const userData = this._localStorage.get('UserData');
    const SoucsToken = this.msalService.instance.getAllAccounts().length > 0 ? true : false;
    if (SoucsToken) {
      this.msalService.instance.setActiveAccount(this.msalService.instance.getAllAccounts()[0])
      if (this.isIdle()) {
        this.soucsAdminLogout();
        return
      }
      else {
        this._localStorage.set('lastPing', new Date());
        this._router.navigateByUrl('/dashboard');
        this._toastr.success('Welcome back Soucs Admin!! <br> Your session still alive', '', { enableHtml: true });
      }

    }

    if (userData) {
      if (this.isIdle()) {
        this.schoolAdminLogout();
        return;
      }
      else {
        this._localStorage.set('lastPing', new Date());
        this._router.navigateByUrl('/dashboard');
        if (!this._localStorage.get('concurrent-login')) {
          this._toastr.success('Welcome back School Admin!! <br> Your session still alive', '', { enableHtml: true });
        }
      }
    }
  }

  public authenticatedUser(email: string, name: string, schoolId: number, schoolName: string, typeName: string, token: string, expirein: number, isDeputyAdmin: number, id: number, refresh_token: string) {
    const expirationDate = new Date(expirein / 1000);
    const expiresIn = (expirationDate.getTime() / 1000);
    const expiresTime = (parseInt(expiresIn.toString()));
    //   const user = new User(email, name, schoolId, schoolName, typeName,isDeputyAdmin);
    const user = new User(email, name, schoolId, schoolName, typeName, isDeputyAdmin, id);

    this.user.next(user);
    this.httpService.setCookie('_token', token);
    this.httpService.setCookie('_refresh_token', refresh_token);
    this.httpService.setCookie('_tokenExpirationDate', JSON.stringify(expirationDate));
    this.autoSignOut(expiresTime);
    // this.httpService.setCookie('UserData', JSON.stringify(user));
    this.httpService.setCookie('UserData', this._localStorage.encryptData
      (JSON.stringify(user)));
  }

  autoSignOut(expirationDuration: number) {
    this.tokenExpirationTimer = setInterval(() => {
      this.schoolAdminLogout();
    }, expirationDuration);
    clearInterval(this.tokenExpirationTimer);
  }

  schoolAdminLogout() {
    this.httpService.getCookie(('UserData'))
    let schoolAdminId = JSON.parse(this._localStorage.decryptData(this._localStorage.get(('UserData'))))['id'];
    this.spinner.show();
    this._apollo
      .mutate({
        mutation: logoutQuery,
        variables: { schoolAdminId: schoolAdminId, soucsAdminEmail: '' },
        fetchPolicy: 'no-cache'
      }).subscribe(({ data }) => {
        this.user.next(null);
        this.spinner.hide();
        this.httpService.removeAllCookie();
        this._toastr.success('Logged out successfully');
        window.location.reload();
      }, error => {
        this.spinner.hide();
        this._errorHandler.manageError(error, true);
      });

  }

  soucsAdminLogout() {
    this.spinner.show();
    const allAccounts = this.msalService.instance.getAllAccounts()
    if (allAccounts.length) {
      this._apollo
        .mutate({
          mutation: logoutQuery,
          variables: { schoolAdminId: 0, soucsAdminEmail: allAccounts[0].username },
          fetchPolicy: 'no-cache'
        }).subscribe(({ data }) => {
          this.spinner.hide();
          this.msalService.logoutRedirect({ account: allAccounts[0] });
          this._localStorage.removeAll();
          this._toastr.success('Logged out successfully');
          window.location.reload();
        }, error => {
          this.spinner.hide();
          this._errorHandler.manageError(error, true);
        });
    }
    else {
      this.msalService.logoutRedirect();
      this._localStorage.removeAll();
    }



  }

  public logout() {
    let isSchoolAdmin = this._localStorage.isSchoolAdmin();
    if (isSchoolAdmin) {
      this.schoolAdminLogout();
    } else {
      this.soucsAdminLogout();
    }
  }

}
